import React from 'react'
import './JobOffer.scss'
import ReactHtmlParser from 'react-html-parser'

export const JobOffer = ({ jobOffer }) => {
	const {
		compensation,
		date,
		description,
		diplomas,
		experience,
		hours_a_week,
		qualifications,
		responsibilities,
		title,
	} = jobOffer

	return (
		<article className="job-offer">
			<div>
				<h2 className="job-offer__title">{title}</h2>
				<div
					className="job-offer__date"
					dangerouslySetInnerHTML={{
						__html: date,
					}}
				/>
				<div className="job-offer__hours">
					Uren per week: {hours_a_week}
				</div>
				<div
					className="job-offer__description"
					dangerouslySetInnerHTML={{
						__html: description,
					}}
				/>
				<div
					className="job-offer__responsibilities"
					dangerouslySetInnerHTML={{
						__html: responsibilities,
					}}
				/>
				<div
					className="job-offer__qualifications"
					dangerouslySetInnerHTML={{
						__html: qualifications,
					}}
				/>
				<div className="job-offer__experience">
					<strong>Ervaring:</strong> {ReactHtmlParser(experience)}
				</div>
				<div className="job-offer__diplomas">
					<strong>Diploma's:</strong> {ReactHtmlParser(diplomas)}
				</div>
				<div className="job-offer__compensation">
					<strong>Compensatie:</strong>{' '}
					{ReactHtmlParser(compensation)}
				</div>
			</div>
			<a
				className="job-offer__link"
				title="Email In Balans Alkmaar je CV"
				href={`mailto:info@inbalansalkmaar.nl?subject=Vacature%20${title}`}
			>
				Stuur een mail!
			</a>{' '}
		</article>
	)
}

import React from 'react'
import SecondaryLayout from '../layouts/SecondaryLayout'
import Column from '../layouts/Column'
import { graphql } from 'gatsby'
import Title from '../components/Title/Title'
import PagePadding from '../components/PagePadding/PagePadding'
import PaginationNavigation from '../components/PaginationNavigation/PaginationNavigation'
import { Helmet } from 'react-helmet'
import { JobOffer } from '../components/JobOffer/JobOffer'

export default function Vacancies(props) {
	const pageData = props.data.allWordpressAcfVacatures.edges[0].node.acf
	const { job_offer } = pageData

	return (
		<SecondaryLayout>
			<Helmet>
				<title>
					Vacatures | In Balans Alkmaar Schaats- en Skeelerschool
				</title>
				<meta
					name="description"
					content={
						'Wil jij onderdeel worden van het team bij In Balans Alkmaar? Lees hier meer!'
					}
				/>
			</Helmet>

			<PagePadding>
				<Column>
					<Title
						heading={1}
						text={'Vacatures bij In Balans Alkmaar'}
						wave={true}
					/>
					<Column>
						<section className="job-offer__container">
							{job_offer.map((jobOffer) => {
								return <JobOffer jobOffer={jobOffer} />
							})}
						</section>
						<p>
							Open sollicitatie? Stuur een email naar{' '}
							<a
								className="job-offer__overall-link"
								title="Email In Balans Alkmaar je CV"
								href="mailto:info@inbalansalkmaar.nl"
							>
								info@inbalansalkmaar.nl
							</a>{' '}
							en wie weet zit jij binnenkort in ons team!
						</p>
					</Column>
				</Column>
				<PaginationNavigation pageContext={props.pageContext} />
			</PagePadding>
		</SecondaryLayout>
	)
}

export const pageQuery = graphql`
	query {
		allWordpressAcfVacatures {
			edges {
				node {
					acf {
						job_offer {
							title
							responsibilities
							qualifications
							hours_a_week
							description
							date
							diplomas
							experience
							compensation
						}
					}
				}
			}
		}
	}
`
